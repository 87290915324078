.inputContainer input {
  height: 50px;
  padding: 15px;
  outline: 0 !important;
  color: #000;
}

.inputContainer textarea {
  padding: 15px;
  outline: 0 !important;
  color: #000;
}

.inputContainer select {
  height: 50px;
  padding: 15px;
  outline: 0 !important;
  color: #000;
}

option {
  font-family: 'forma-djr-micro', Arial;
}

.inputContainer input[type='radio'] {
  display: inline-block;
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.radioFlex {
  display: grid;
  align-items: center;
  cursor: pointer;
}

.inputTheme {
  cursor: pointer;
}

.inputTheme:hover {
  outline: 1px solid #e6e6e6;
}

.inputTheme label {
  cursor: pointer;
}
