.default-link:hover {
  color: #00b388 !important;
}

.currentPage {
  color: #00b388 !important;
}

.endPage {
  color: #616161 !important;
}

.filterContainer input {
  height: 40px;
  padding: 0px 20px;
  color: #000;
}
.filterContainer select {
  height: 40px;
  padding: 0px 20px;
  color: #000;
}

.filterContainer input:focus {
  outline: 2px solid #00b388;
}
.filterContainer select:focus {
  outline: 2px solid #00b388;
}
