.emailInput {
  background-color: #ffffff;
  max-width: 390px;
  border: 1px solid #5c5c5c;
  font-size: 17px !important;
  padding: 16px 22px !important;
  height: 55px !important;
  line-height: 22px !important;
}

.emailInput:focus {
  border: none !important;
  outline: 2px solid #00b388;
}

.passwordInput:focus {
  border: none !important;
  outline: 2px solid #00b388;
}

.passwordInput {
  background-color: #ffffff;
  max-width: 390px;
  border: 1px solid #5c5c5c !important;
  font-size: 17px !important;
  padding: 16px 22px !important;
  height: 55px !important;
  line-height: 22px !important;
  border-radius: 8px;
}

.button {
  text-decoration: none !important;
  background-color: #00b388;
  padding: 10px;
  padding-left: 50px;
  padding-right: 50px;
  border-radius: 5px;
  color: #ffffff !important;
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .button {
    text-decoration: none !important;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
    color: #ffffff !important;
    cursor: pointer;
  }
}

.button img {
  width: 25px;
  animation: rotation 0.5s infinite linear;
}

.rotate {
  animation: rotation 0.5s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

button.active.focus,
button.active:focus,
button.focus,
button:active.focus,
button:active:focus,
button:focus {
  outline: none;
  box-shadow: none;
}
