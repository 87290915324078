@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import url('https://use.typekit.net/jbt7rox.css');

* {
  font-family: 'forma-djr-micro', Calibri, Arial, sans-serif;
}
.forma-regular {
  font-family: 'forma-djr-micro', Calibri, Arial, sans-serif;
}

* {
  font-family: 'forma-djr-micro';
}

html {
  scroll-behavior: smooth !important;
}
