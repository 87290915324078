.navBar .nav-title {
  color: #000 !important;
}

.log-out-icon {
  max-width: 30px;
}
nav ul {
  list-style: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  z-index: 300;
}

.nav {
  display: inline-block;
  color: #000;
  text-align: left;
}

.nav a {
  display: block;
  padding: 0;
  line-height: inherit;
  cursor: pointer;
}

.nav__menu {
  line-height: 45px;
}

.nav__menu-item {
  color: #fff;
}

.nav__menu-item:hover {
  color: #00b388;
  text-decoration: none !important;
}

.navBarContainer {
  display: flex;
  background-color: #323232;
}

@media screen and (min-width: 1024px) {
  .navBarContainer {
    display: flex;
  }
}
