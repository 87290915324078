.forma-regular {
  font-family: 'forma-djr-micro', Calibri, Arial, sans-serif;
}

.App-header {
  background-color: #202020;
  min-height: 100vh;
  color: white;
}

.App-link {
  color: #00b388;
}

.lp-preview {
  height: 720px;
  background-repeat: no-repeat;
}

/* Modal container */
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
}

/* Modal content */
.modal-content {
  background-color: #fefefe; /* White background */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
  max-width: 400px;
  width: 100%;
  display: grid;
}

/* Close button */
.close {
  color: #aaa;
  float: right;
  max-width: 30px;
  background-color: transparent !important;
  font-size: 28px;
  line-height: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
}

/* Input field */
.inputContainer input[type='text'] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Error message */
.error {
  color: #ff0000;
  margin-bottom: 10px;
}

/* Next button */
.nextButton {
  background-color: #00ad81;
  color: #fff;
  max-width: 100px;
  margin-top: 20px;
  height: 40px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  float: right;
}

.nextButton:hover {
  background-color: #028665;
}
